import { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { Stack } from "@mui/material";
import { useAtom } from "jotai";
import "react-image-crop/dist/ReactCrop.css";

import { ConfirmDialog } from "../ConfirmDialog";

import { selectedImageFilenameAtom, selectedImageSrcAtom } from "@/atom/global";

import type { Crop, PixelCrop } from "react-image-crop";

export function ImageCropDialog({
  open,
  title,
  onClose,
  onCancel,
  onSubmit,
  aspect = 5 / 4,
}: {
  open: boolean;
  title: string;
  aspect?: number;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: (file: File) => void | PromiseLike<void>;
}) {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = useAtom(selectedImageSrcAtom);
  const [imgFileName, setImgFileName] = useAtom(selectedImageFilenameAtom);
  const [crop, setCrop] = useState<Crop>({
    height: 100,
    width: 100,
    unit: "%",
    x: 0,
    y: 0,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          height: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onCloseDialog() {
    setImgSrc("");
    setImgFileName("");
    onClose();
  }

  function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise((resolve) => {
      canvas.toBlob(resolve, "image/png", 0.65);
    });
  }

  function blobToFile(theBlob: Blob, fileName: string) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }

  async function cropImage(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    completedCrop: PixelCrop | null
  ) {
    const finalWidth = completedCrop ? completedCrop.width : image.width;
    const finalHeight = completedCrop ? completedCrop.height : image.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = 1; // window.devicePixelRatio;
    canvas.width = Math.floor(finalWidth * scaleX * pixelRatio);
    canvas.height = Math.floor(finalHeight * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);

    const cropX = completedCrop ? completedCrop.x * scaleX : 0;
    const cropY = completedCrop ? completedCrop.y * scaleY : 0;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();
    // 4) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 3) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 2) Scale the image
    ctx.scale(1, 1);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );
    ctx.restore();

    const blob = await canvasToBlob(canvas);
    if (blob) {
      const file = blobToFile(blob, imgFileName);
      await onSubmit(file);
    }
  }

  return (
    <ConfirmDialog
      title={title}
      loading={loading}
      open={open}
      onClose={onCloseDialog}
      onConfirm={async () => {
        if (imgRef.current && previewCanvasRef.current) {
          setLoading(true);
          await cropImage(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop
          );
          setLoading(false);
        }
      }}
      onCancel={onCancel}
      confirmMessage="ครอบตัดและบันทึก"
    >
      <Stack sx={{ backgroundColor: "#000000" }}>
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            ruleOfThirds
            keepSelection
            style={{
              maxHeight: "65vh",
              backgroundColor: "#000000",
              margin: "0 auto",
            }}
          >
            <img ref={imgRef} alt="crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
        {
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "none",
              objectFit: "contain",
              width: completedCrop?.width,
              height: completedCrop?.height,
            }}
          />
        }
      </Stack>
    </ConfirmDialog>
  );
}
