import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "react-query";

import { createMember } from "@/services/member";
import { MemberInput } from "@/models";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { getApiErrorMessage } from "@/utils";
import {
  MemberProfileEditor,
  useMemberEditorForm,
} from "@/features/member/pages/MyMemberListPage/MemberProfileEditor";
import { usePurchaseMembershipEditorForm } from "@/features/member/components/PurchaseMembershipEditor";
import { usePurchaseProductPTEditorForm } from "@/features/member/components/PurchaseProductPTEditor";
import { PurchaseProductEditor } from "@/features/member/pages/MyMemberListPage/PurchaseProductEditor";
import { Summary } from "@/features/member/pages/MyMemberListPage/Summary";
import { MemberAlertDialog } from "@/features/member/components/MemberAlertDialog";

import { usePaymentEditorForm } from "./Payment/PaymentEditor";

import type { AxiosErrorWithData } from "@/client/api";

const steps = ["ข้อมูลส่วนตัว", "การเลือกซื้อสินค้า", "ตรวจสอบข้อมูล"];

export function AddMemberDialog({
  open: isOpen,
  onClose,
}: {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { staffId } = useCurrentUser();

  const [isBlocking, setIsBlocking] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [count, setCount] = useState<number>(0);

  const { mutate: add, isLoading } = useMutation(createMember, {
    onSuccess: async ({ memberLeft, isShow }) => {
      const total = memberLeft - 1;
      enqueueSnackbar("เพิ่มสมาชิกสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries("members");
      close("success");
      if (isShow) {
        setIsAlert(true);
        setCount(total);
      }
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      if (error.response?.data.message === "จำนวนสมาชิกของคุณครบกำหนดแล้ว") {
        setIsAlert(true);
        setCount(0);
      }
      close("error");
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  const [activeStep, setActiveStep] = useState(0);

  const queryClient = useQueryClient();

  const showPrevButton = activeStep !== 0;
  const showSubmitButton = activeStep + 1 === steps.length;

  const ref = useRef<HTMLDivElement>(null);

  const {
    control: memberProfileControl,
    trigger: memberProfileTrigger,
    reset: memberProfileReset,
    getValues: getMemberProfileValues,
    formState: memberProfileFormState,
  } = useMemberEditorForm();

  const {
    control: membershipControl,
    trigger: membershipTrigger,
    reset: membershipReset,
    getValues: getPurchaseMembershipValues,
  } = usePurchaseMembershipEditorForm();

  const {
    control: packagePTControl,
    trigger: packagePTTrigger,
    reset: packagePTReset,
    getValues: getPurchaseProductPTValues,
  } = usePurchaseProductPTEditorForm({ required: false });

  const {
    control: paymentControl,
    getValues: getPaymentValues,
    trigger: paymentTrigger,
    reset: paymentReset,
  } = usePaymentEditorForm();

  const formSteps = [
    <MemberProfileEditor control={memberProfileControl} />,
    <PurchaseProductEditor
      membershipControl={membershipControl}
      packagePTControl={packagePTControl}
      paymentFormControl={paymentControl}
      packagePTReset={packagePTReset}
    />,
    <Summary
      getMemberProfileValues={getMemberProfileValues}
      getMembershipValues={getPurchaseMembershipValues}
      getProductPTValues={getPurchaseProductPTValues}
    />,
  ];
  const currentForm = formSteps[activeStep];

  // TODO: refactor
  const triggers = [
    memberProfileTrigger,
    async () => {
      const isMembershipValid = await membershipTrigger();
      const isProductPTValid = await packagePTTrigger();
      const isPaymentValid = await paymentTrigger();
      return isMembershipValid && isProductPTValid && isPaymentValid;
    },
  ];

  async function onClickNext() {
    const isValid = await triggers[activeStep]();

    if (isValid) setActiveStep((prev) => ++prev);
    scrollToTop();
  }

  function onClickPrev() {
    setActiveStep((prev) => --prev);
    scrollToTop();
  }

  function scrollToTop() {
    ref.current?.scrollTo(0, 0);
  }

  function submit() {
    const memberProfile = getMemberProfileValues();
    const purchaseMembership = getPurchaseMembershipValues();
    const purchaseProductPT = getPurchaseProductPTValues();
    const payment = getPaymentValues();

    const {
      branch: taxBranch,
      name: taxName,
      no: taxNo,
      type: taxType,
    } = memberProfile.invoice;
    const {
      line: taxAddress,
      postcode: taxPostCode,
      province: taxProvince,
      district: taxDistrict,
      subdistrict: taxSubdistrict,
      required,
    } = memberProfile.invoice.address;
    const {
      line: address,
      district,
      subdistrict,
      province,
      postcode: postCode,
    } = memberProfile.currentAddress;
    const {
      email,
      nationalIdCard: idCard,
      firstName,
      lastName,
      nickname,
      dateOfBirth: birthDate,
      gender,
      height,
      weight,
      maritalStatus,
      bloodGroup,
      career,
      phone: phoneNo,
      emergencyContactName: emergencyPerson,
      emergencyContactPhone: emergencyPhoneNo,
      relationship: emergencyRelation,
      line,
      facebook,
      contactOther,
      channel,
      channelOther,
      channelFriend,
    } = memberProfile;
    const data: MemberInput = {
      taxAddress,
      taxPostCode,
      taxProvince,
      taxDistrict,
      taxSubdistrict,
      taxBranch,
      taxName,
      taxNo,
      taxType: required ? taxType : null,
      profile: {
        address,
        district,
        subdistrict,
        postCode,
        province,
        email,
        idCard,
        firstName,
        lastName,
        nickname,
        birthDate,
        gender,
        height,
        weight,
        maritalStatus,
        bloodGroup,
        career,
        phoneNo,
        emergencyPerson,
        emergencyPhoneNo,
        emergencyRelation,
        line,
        facebook,
        contactOther,
        channel,
        channelOther,
        memberId: channelFriend?.id ?? undefined,
      },
      staffsId: staffId,
      productMembershipId: +purchaseMembership.membership.id,
      membershipName: purchaseMembership.membership.name,
      membershipStartedAt: purchaseMembership.dateRange.start.toJSDate(),
      membershipEndedAt: purchaseMembership.dateRange.end.toJSDate(),
      membershipRemark: purchaseMembership?.note ?? null,
      membershipDiscountPercentage: purchaseMembership.discountPercentage ?? 0,
      membershipDiscountBaht: purchaseMembership.discountBaht ?? 0,
      membershipPrice: purchaseMembership.membership.price,
      productPTId: purchaseProductPT?.packagePT?.id
        ? +purchaseProductPT.packagePT.id
        : null,
      ptName: purchaseProductPT.packagePT?.name,
      ptStartedAt: purchaseProductPT.dateRange.start.toJSDate(),
      ptEndedAt: purchaseProductPT.dateRange.end.toJSDate(),
      ptRemark: purchaseProductPT?.note ?? null,
      ptPrice: purchaseProductPT.packagePT?.price,
      ptDiscountPercentage: purchaseProductPT.discountPercentage ?? 0,
      ptDiscountBaht: purchaseProductPT.discountBaht ?? 0,
      paymentMethodCode: payment.paymentMethod,
      bankId: payment.bank?.id,
      isInstallment: payment.isInstallment === "true",
      installmentMonths: payment.installmentMonths || undefined,
      interestRate: payment.interestRate || undefined,
    };

    add(data);
  }

  const isDirty = Object.keys(memberProfileFormState.touchedFields).length > 0;

  function close(status: "cancel" | "success" | "error" = "cancel") {
    onClose(status);
    setActiveStep(0);
    memberProfileReset();
    membershipReset();
    packagePTReset();
    paymentReset();
  }

  const title = "เพิ่ม Member";

  return (
    <>
      <Dialog
        open={isOpen}
        title={title}
        onClose={() => (isDirty ? setIsBlocking(true) : close())}
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          {title}
          <Tooltip title="ปิด">
            <IconButton
              onClick={() => (isDirty ? setIsBlocking(true) : close())}
              sx={{ ml: "auto" }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent ref={ref}>
          <Stepper activeStep={activeStep} sx={{ py: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {currentForm}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => (isDirty ? setIsBlocking(true) : close())}
          >
            ยกเลิก
          </Button>
          <Stack direction="row" gap={2.5}>
            {showPrevButton && (
              <Button color="primary" variant="outlined" onClick={onClickPrev}>
                ย้อนกลับ
              </Button>
            )}
            {showSubmitButton ? (
              <LoadingButton
                loading={isLoading}
                color="primary"
                variant="contained"
                onClick={submit}
              >
                บันทึก
              </LoadingButton>
            ) : (
              <Button color="primary" variant="contained" onClick={onClickNext}>
                ต่อไป
              </Button>
            )}
          </Stack>
        </DialogActions>
        <ConfirmDialog
          title="คุณต้องการยกเลิกหรือไม่"
          open={isBlocking}
          onClose={() => isDirty && (void setIsBlocking(false) || close())}
          onConfirm={() => isDirty && setIsBlocking(false)}
          maxWidth="xs"
          confirmMessage="ไม่ใช่"
          cancelMessage="ใช่"
        />
      </Dialog>
      <MemberAlertDialog
        open={isAlert}
        onClose={() => void setIsAlert(false)}
        count={count}
      />
    </>
  );
}
