import { api } from "@/client/api";
import { configs } from "@/configs";
import {
  PaymentMethodCode,
  Product,
  ProductMembership,
  ProductMembershipInput,
  ProductPT,
  ProductPTInput,
} from "@/models";
import { parseDateTimeFromPrisma } from "@/utils";

type GetProductMemberships = {
  data: ProductMembership[];
  total: number;
};

type GetProductPTs = {
  data: ProductPT[];
  total: number;
};

type GetProducts = {
  data: Product[];
  total: number;
};

type PurchaseProductsInput = {
  memberId?: number;
  staffId: number;
  purchaseProducts: {
    productId: number;
    name: string;
    price: number;
    quantity: number;
    discountBaht: number;
    discountPercentage: number;
  }[];
  paymentMethodCode: PaymentMethodCode;
  bankId?: number;
  isInstallment?: boolean;
  installmentMonths?: number;
  interestRate?: number;
};

const productMembershipPath = "/sale/products/memberships";
const productPTPath = "/sale/products/pts";
const productPath = "/sale/products";

export async function getProductMemberships(query = "") {
  const { data } = await api.get<GetProductMemberships>(
    `${configs.apiUrl}${productMembershipPath}?${query}`
  );
  return data;
}

export async function getProductMembership(id: string) {
  const { data } = await api.get<ProductMembership>(
    `${configs.apiUrl}${productMembershipPath}/${id}`
  );
  return data;
}

export async function createProductMembership(body: ProductMembershipInput) {
  const { data } = await api.post<ProductMembership>(
    `${configs.apiUrl}${productMembershipPath}`,
    body
  );
  return data;
}

export async function deleteProductMembership(id: string) {
  const { data } = await api.delete<ProductMembership>(
    `${configs.apiUrl}${productMembershipPath}/${id}`
  );
  return data;
}

export async function getProductPTs(query = "") {
  const { data } = await api.get<GetProductPTs>(
    `${configs.apiUrl}${productPTPath}?${query}`
  );
  return data;
}

export async function getProductPT(id: string) {
  const { data } = await api.get<ProductPT>(
    `${configs.apiUrl}${productPTPath}/${id}`
  );
  return data;
}

export async function createProductPT(body: ProductPTInput) {
  const { data } = await api.post<ProductPT>(
    `${configs.apiUrl}${productPTPath}`,
    body
  );
  return data;
}

export async function deleteProductPT(id: string) {
  const { data } = await api.delete<ProductPT>(
    `${configs.apiUrl}${productPTPath}/${id}`
  );
  return data;
}

export async function getProducts(query = "") {
  const { data } = await api.get<GetProducts>(
    `${configs.apiUrl}${productPath}?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetProducts;
}

export async function getOpenProducts() {
  const { data } = await api.get<GetProducts>(
    `${configs.apiUrl}${productPath}?isPublished=true&limit=300`
  );
  return parseDateTimeFromPrisma(data) as GetProducts;
}

export async function getProduct(id: string) {
  const { data } = await api.get<Product>(
    `${configs.apiUrl}${productPath}/${id}`
  );
  return parseDateTimeFromPrisma(data) as Product;
}

export async function purchaseProducts(input: PurchaseProductsInput) {
  await api.post(`${configs.apiUrl}${productPath}/purchase`, input);
}
