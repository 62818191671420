import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import { BrowserTracing } from "@sentry/tracing";
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "firebase/analytics";
import { setLocale } from "yup";

import { locales } from "@/lib/yup/locales";
import "@/lib/yup/methods";
import { configs } from "./configs";

if (configs.sentryDSN) {
  Sentry.init({
    dsn: configs.sentryDSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: configs.env,
    release: configs.version,
  });
}
TagManager.initialize({ gtmId: configs.googleTagManagerId || "" });
const app = initializeApp(configs.firebase);
getAnalytics(app);
setLocale(locales);
