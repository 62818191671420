import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useLocation, useSearchParams } from "react-router-dom";

import { getMemberStatus } from "@/utils";
import { MemberStatus } from "@/models";
import { ProfileImageEditor } from "@/components/ProfileImageEditor";

import { MemberStatusLabel } from "../../components/MemberStatusLabel";
import { MemberProfileDetail } from "../../components/MemberProfileDetail";
import { EditMemberDialog } from "./EditMemberDialog";
import { BanMemberDialog } from "../../components/BanMemberDialog";
import { MemberVerifyDialog } from "../../pages/MyMemberListPage/MemberVerifyDialog";

import type { Member } from "@/models";

const QUERY_KEY = "member";

export type MemberProfileProps = {
  data: Member;
};

export function MemberProfile({ data }: MemberProfileProps) {
  const { id, code: memberCode, profile, accountId } = data;
  const location = useLocation();
  const path = location?.pathname ?? "";
  const isAnother = path.includes("another");

  const memberStatus = getMemberStatus(data);
  const isMemberBanned = memberStatus === MemberStatus.Ban;

  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  function openEditDialog() {
    searchParams.set("dialog", "edit");
    setSearchParams(searchParams);
  }

  function openBanDialog() {
    searchParams.set("dialog", "member-ban");
    setSearchParams(searchParams);
  }

  function openVerifyDialog() {
    searchParams.set("dialog", "member-verify");
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: dialog === "edit",
    onClose: onCloseDialog,
    id,
    data,
  };

  const verifyDialog = {
    title: "ยืนยันสมาชิก",
    open: dialog === "member-verify",
    id,
    onClose: onCloseDialog,
    data,
  };

  const banDialog = {
    open: data && dialog === "member-ban",
    onClose: onCloseDialog,
    data,
    fetchKey: QUERY_KEY,
  };

  const profileImageEditor = {
    profile: profile,
    fetchKey: QUERY_KEY,
  };

  return (
    <Card>
      <Stack>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">{memberCode}</Typography>
              <MemberStatusLabel variant="subtitle1" status={memberStatus} />
            </Stack>
          }
          action={
            <Stack direction="row">
              {!isAnother && (
                <>
                  <Button variant="text" onClick={openBanDialog}>
                    <Typography
                      variant="subtitle2"
                      color={isMemberBanned ? "info.main" : "error.main"}
                    >
                      {isMemberBanned ? "ปลดระงับสมาชิก" : "ระงับสมาชิก"}
                    </Typography>
                  </Button>
                  {!accountId && (
                    <Tooltip title="ยืนยันสมาชิก">
                      <IconButton onClick={openVerifyDialog}>
                        <QrCodeIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="แก้ไขสมาชิก">
                    <IconButton onClick={openEditDialog}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Stack>
          }
        />
        <CardContent>
          <Stack gap={4}>
            <ProfileImageEditor {...profileImageEditor} />
            <MemberProfileDetail data={data} />
          </Stack>
          <EditMemberDialog {...editDialog} />
          <BanMemberDialog {...banDialog} />
          <MemberVerifyDialog {...verifyDialog} />
        </CardContent>
      </Stack>
    </Card>
  );
}
